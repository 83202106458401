import { Box, Skeleton, Grid } from "@mui/material";
import React from "react";
import { StyledEventSquareCardWrapper } from "./Styles";

const SquareLoadingEventCard = () => {
  return (
    <StyledEventSquareCardWrapper

    >
      <Grid container spacing={4}>
        {/* Image Section */}
        <Grid item xs={10}>
          <Skeleton variant="rounded" width="100%" height="180px"        sx={{
       bgcolor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: "8px",
        padding: "10px",
        // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)", // Add shadow for better appearance
      }}/>
        </Grid>

        {/* Date and Place */}
        <Grid item xs={6}>
          <Skeleton variant="text" width="80%"       sx={{
        bgcolor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: "8px",
        padding: "10px",
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add shadow for better appearance
      }} />
          <Skeleton variant="text" width="60%" />
        </Grid>

        {/* Buttons */}
        <Grid item xs={6}>
          <Skeleton variant="rectangular" width="80%" height="40px" sx={{ mb: 1 }}  />
          <Skeleton variant="rectangular" width="80%" height="40px" />
        </Grid>

        {/* Title */}
        <Grid item xs={12}>
          <Skeleton variant="text" width="70%" sx={{ mt: 1 }} />
        </Grid>

        {/* Tags and Description */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <Skeleton variant="text" width="20%" height="30px" />
            <Skeleton variant="text" width="20%" height="30px" />
            <Skeleton variant="text" width="20%" height="30px" />
          </Box>
          <Skeleton variant="text" width="90%" sx={{ mt: 2 }} />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
        </Grid>
      </Grid>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareLoadingEventCard;
