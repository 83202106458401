import { Modal, Paper } from "@mui/material";
import { styled } from "@mui/system";
//import ModalWrapper from "components/ModalWrapper";
import React, { useEffect } from "react";
import Checkout from "../Checkout";
import { colors } from "constants/themeConstants";

const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  background: colors.background.primary,
  // width: "50%",
  // height: "80%",
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
  borderRadius: "10px",
  maxHeight: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  paddingRight: "0.5rem",
  paddingLeft: "0.5rem",

  // maxWidth: "60vw",
}));

const CheckoutModal = ({ modalData, setModalData }) => {
  const {
    open,
    id,
    price,
    name,
    currency,
    bundle,
    eventId,
    tickets,
    isFree,
    isRecurring,
  } = modalData;


  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& ::-webkit-scrollbar": { display: "none" },
      }}
      open={open}
      onClose={() => setModalData({ open: false, id, price, name })}
    >
      <StyledModalItemsContainer className="w-[100%] md:w-[80%] lg:w-[70%] xl:w-[50%] overflow-x-hidden">
        {/* <StyledModalItemsContainer className="w-[80%] md:w-[50%]"> */}
        <Checkout
          eventId={eventId}
          id={id}
          tickets={tickets}
          price={price}
          bundle={bundle}
          isFree={isFree}
          currency={currency}
          name={name}
          setModalData={setModalData}
          isRecurring={isRecurring}
        />
      </StyledModalItemsContainer>
    </Modal>
  );
};

export default CheckoutModal;
